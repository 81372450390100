<template>
  <div>
    <div class="tabNav">
      <div v-dragscroll>
        <div
          v-for="(item, index) in itemize"
          :key="index"
          class="navItem"
          @click="navChange(index)"
        >
          <div :class="navIndex == index ? 'name navActive' : 'name'">
            {{ item.name }}
          </div>
          <div class="xian" v-if="index < itemize.length - 1"></div>
        </div>
      </div>
    </div>

    <div class="outer">
      <div
        class="newsItem"
        v-for="(item, index) in newList"
        :key="index"
        @click="newsDetails(item)"
      >
        <img :src="item.image" alt="" />
        <div class="itemCont">
          <div class="title">{{ item.title }}</div>
          <div class="cont" v-html="item.content"></div>
          <div class="see">
            <div>{{ $t("newsCenter.NC_see") }}</div>
            <img src="@/assets/img/checklan.png" alt="" />
          </div>
        </div>
      </div>
      <div class="paging">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="10"
          layout="total,prev,pager,next,jumper"
          :total="count"
          prev-text="上一页"
          next-text="下一页"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：新闻中心
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-15 15:45
 */
export default {
  data() {
    return {
      itemize: [],
      navIndex: 0,
      currentPage: 1,
      newList: "",
      count: 0,
    };
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScrollbox, true);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollbox, true);
    this.geNavList();
  },
  methods: {
    /**
     * 头部切换
     * 刘嘉鑫
     * 2022-8-15
     */
    navChange(index) {
      this.navIndex = index;
      this.currentPage = 1;
      this.getNewsInfo();
    },

    /**
     * 分页
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.getNewsInfo();
    },

    /**
     * 分类
     * 刘嘉鑫
     * 2022-8-17
     */
    geNavList() {
      this.$request({
        url: "/Index/newsType",
        data: {},
      }).then((res) => {
        console.log("新闻分类", res);
        this.itemize = res.news_type;
        this.getNewsInfo();
      });
    },

    /**
     * 新闻列表
     * 刘嘉鑫
     * 2022-8-17
     */
    getNewsInfo() {
      this.$request({
        url: "/Index/newsList",
        data: {
          type_id: this.itemize[this.navIndex].id, // 分类id
          page: this.currentPage,
        },
      }).then((res) => {
        console.log("新闻列表", res);
        this.newList = res.news_list;
        this.count = res.news_count;
        for (let i in this.newList) {
          var re1 = new RegExp("<.+?>", "g"); //匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
          this.newList[i].content = this.newList[i].content.replace(re1, ""); //执行替换成空字符
        }

        setTimeout(() => {
          this.$(".newsItem").addClass("showDiv");
        }, 200);

        // this.handleScrollbox();
      });
    },

    /**
     * 新闻详情
     * 刘嘉鑫
     * 2022-8-18
     */
    newsDetails(item) {
      this.$router.push({
        path: "newsCenterDetails",
        query: { id: item.id, title: item.title },
      });
    },

    /**
     * 动画效果
     * 刘嘉鑫
     * 2022-8-23
     */
    handleScrollbox() {
      this.currentScroll = window.pageYOffset; //表示当前滚动的位置
      // this.$(".newsItem").addClass("showDiv");
      // if (this.currentScroll <= this.$(".outer").offset().top - 100) {
      //   console.log(123123);
      //   this.$(".newsItem").addClass("showDiv");
      // }
    },
  },
};
</script>
<style lang='scss' scoped>
.tabNav {
  // padding: 0 220px;
  background: #ffffff;

  > div {
    width: 100%;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  > div:nth-child(1){
    padding-left: 220px;
  }

  .navItem {
    padding: 25px 0 0;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    // width: 300px !important;
  }

  .name {
    padding: 0 18px 21px;
    border-bottom: 4px solid transparent;
    font-size: 18px;
    font-family: FZFengYaSongS-GB;
    font-weight: bold;
    color: #444444;
    margin: 0 42px;
  }

  .xian {
    width: 2px;
    height: 15px;
    background: #cccccc;
    margin: 5px 0 0 0;
  }

  .navActive {
    color: #1a2a60;
    border-bottom: 4px solid #1a2a60;
  }
}

.outer {
  padding: 80px 310px 40px;
  background: #f6f6f6;
  overflow: hidden;
}

.newsItem {
  padding: 10px 130px 10px 10px;
  background: #ffffff;
  margin-bottom: 40px;
  display: flex;
  overflow: hidden;
  transition: all 0.8s ease-out 0s;
  transform: translate(-50px, 0px);
  opacity: 0;
  > img {
    width: 180px;
    height: 180px;
    margin-right: 40px;
    object-fit: cover;
  }

  .itemCont {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    width: 81%;

    .title {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #222222;
      margin-bottom: 19px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal !important;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .cont {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #888888;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .see {
      display: flex;
      align-items: center;
      margin-top: auto;
      > div {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #1a2a60;
      }
      > img {
        width: 20px;
        height: 12px;
        margin-left: 10px;
      }
    }
  }
}

.newsItem:hover {
  .see {
    > img {
      animation: checkImg 0.7s linear infinite;
      position: relative;
    }
  }
}
</style>